import React from "react";

import "./Home.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import Intro1 from "./Intro1";
import Intro2 from "./Intro2";
import Footer from "./Footer";
import Intro3 from "./Intro3";

function Home() {
  return (
    <Container>
      <Navbar className="home" fixed="top" style={{ justifyContent: "space-between" }}>
        <Navbar.Brand href="/">
          <img alt="" src="start_bi.png" width="120px" className="d-inline-block align-center" />{" "}
        </Navbar.Brand>

        <Nav className="mr-sm-2">
          <Nav.Link className="topMenu" href="http://starcoex.co.kr/">
            회사소개
          </Nav.Link>
          <Nav.Link className="topMenu" href="#second">
            멤버십
          </Nav.Link>
          <Nav.Link className="topMenu" href="#third">
            디지털키
          </Nav.Link>

          <Nav.Link className="topMenu" href="#footer">
            다운로드
          </Nav.Link>
        </Nav>
      </Navbar>

      <Intro1 />
      <Intro2 />
      <Intro3 />
      <Footer />
    </Container>
  );
}

export default Home;

import MaterialTable from "material-table";
import React from "react";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import StarRounded from "@material-ui/icons/StarRounded";
import VpnKey from "@material-ui/icons/VpnKey";
import LocalParkingRounded from "@material-ui/icons/LocalParkingRounded";
import { setDate, getTimeStamp } from "../firebase";
import { Button, Col, Form } from "react-bootstrap";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import "./UserTable.css";
import { Card } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  StarRounded: forwardRef((props, ref) => <StarRounded {...props} ref={ref} />),
  VpnKey: forwardRef((props, ref) => <VpnKey {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CheckCircleOutline: forwardRef((props, ref) => <CheckCircleOutline {...props} ref={ref} />),
  HighlightOff: forwardRef((props, ref) => <HighlightOff {...props} ref={ref} />),
};

function checkMember(data) {
  if (data[1] === "star") {
    return <StarRounded />;
  } else if (data[1] === "key") {
    return <VpnKey />;
  } else {
    return <LocalParkingRounded />;
  }
}
function UserTable({ userData }) {
  // const [modalData, setmodalData] = React.useState({});
  const onFormSubmit = e => {
    e.preventDefault();
    console.log(e.target.name.value);
  };
  return (
    <MaterialTable
      icons={tableIcons}
      options={{ detailPanelType: "multiple", exportButton: true, exportAllData: true, pageSize: 10, pageSizeOptions: [10, 15, 30] }}
      columns={[
        // { title: "UID", field: "uid" },
        { title: "이름", field: "name" },
        { title: "핸드폰번호", field: "phone" },
        { title: "포인트", field: "point", type: "numeric" },
        { title: "가입일", field: "created", type: "date", render: rowData => setDate(rowData.created) },
        { title: "최근접속일", field: "last", type: "date", render: rowData => setDate(rowData.last) },
        { title: "가입경로", field: "Funnels" },
        { title: "멤버십", field: "membership", render: rowData => (rowData.membership[0] ? checkMember(rowData.membership) : null) },
      ]}
      data={userData}
      title="회원관리"
      detailPanel={rowData => {
        return (
          <Card className="detailCard">
            <Form className="userForm" onSubmit={onFormSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="2" controlId="formName">
                  <Form.Label>이름</Form.Label>
                  <Form.Control name="name" plaintext value={rowData.name} readOnly />
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="formPhone">
                  <Form.Label>핸드폰번호</Form.Label>
                  <Form.Control plaintext value={rowData.phone} readOnly />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="formCreated">
                  <Form.Label>가입일</Form.Label>
                  <Form.Control plaintext value={getTimeStamp(rowData.created)} readOnly />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="formMembership">
                  <Form.Label>최근접속일</Form.Label>
                  <Form.Control plaintext value={getTimeStamp(rowData.last)} readOnly />
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="formPoint">
                  <Form.Label>포인트</Form.Label>
                  <Form.Control type="number" defaultValue={rowData.point} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="2" controlId="formMember">
                  <Form.Label>멤버십</Form.Label>
                  <Form.Control as="select" defaultValue={rowData.membership[0]}>
                    <option value="true">가입</option>
                    <option value="false">미가입</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="formType">
                  <Form.Label>타입</Form.Label>
                  <Form.Control as="select" defaultValue={rowData.membership[0] ? rowData.membership[1] : undefined} placeholder="멤버십타입">
                    {/* <option value={undefined}>멤버십타입</option> */}
                    <option value="star">스타멤버십</option>
                    <option value="key">디지털키멤버십</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="formDelay">
                  <Form.Label>결제주기</Form.Label>
                  <Form.Control as="select" defaultValue={rowData.membership[2]} placeholder="결제주기">
                    {/* <option value={undefined}>결제주기</option> */}
                    <option value="month">월회비</option>
                    <option value="year">연회비</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formPayday">
                  <Form.Label>다음결제일</Form.Label>
                  <Form.Control type="date" value={rowData.membership[3] && setDate(rowData.membership[3] * 1000)} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="3" controlId="formCar">
                  <Form.Label>등록차량</Form.Label>
                  <Form.Control as="select" defaultValue={rowData.car[0]} placeholder="등록차량">
                    {/* <option value={undefined}>자동차</option> */}
                    {rowData.car.length !== 0 &&
                      rowData.car.map((item, key) => (
                        <option key={key} value={item}>
                          {item.number}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="formCard">
                  <Form.Label>간편결제카드</Form.Label>
                  <Form.Control as="select" placeholder="간편결제카드" defaultValue={rowData.onetouch.length !== 0 ? rowData.onetouch[0].card_name : undefined}>
                    {/* <option value={undefined}>결제카드</option> */}
                    {rowData.onetouch.length !== 0 &&
                      rowData.onetouch.map((item, key) => (
                        <option key={key} value={item}>
                          {item.card_name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formCoupon">
                  <Form.Label>보유 쿠폰</Form.Label>
                  <Form.Control as="select" placeholder="보유 쿠폰" defaultValue={rowData.coupon.length !== 0 ? rowData.coupon[0].name : undefined}>
                    {/* <option value={undefined}>결제카드</option> */}
                    {rowData.coupon.length !== 0 &&
                      rowData.coupon.map((item, key) => (
                        <option key={key} value={item}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Button className="detailBtn" as={Col} variant="danger">
                  등록차량 변경
                </Button>
                <Button className="detailBtn" as={Col} variant="danger">
                  간편결제 변경
                </Button>
                <Button className="detailBtn" as={Col} variant="danger">
                  쿠폰정보 변경
                </Button>
                <Button className="detailBtn" as={Col} variant="danger">
                  전체 저장
                </Button>
              </Form.Row>
            </Form>
          </Card>
          // <pre>
          //   <code>{JSON.stringify(rowData)}</code>
          // </pre>
        );
      }}
      onRowClick={(event, rowData, togglePanel) => {
        // setmodalData(rowData);
        togglePanel();
      }}
    />
  );
}

export default UserTable;

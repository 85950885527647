import { LOGGED_IN, LOGOUT } from "./types";

export const userLogin = (data) => {
  return {
    type: LOGGED_IN,
    payload: data,
  };
};

export const userLogout = () => {
  return {
    type: LOGOUT,
  };
};

import React from "react";
import UserTable from "../../components/UserTable";
import { db } from "../../firebase";

function Users() {
  const [data, setdata] = React.useState([]);
  const GetUser = async () => {
    const snapshot = await db.collection("user").get();
    console.log(snapshot.size);
    const users = snapshot.docs.map((doc) => doc.data());
    console.log(users);
    setdata(users);
  };

  React.useEffect(() => {
    console.log("effect!");
    GetUser();
  }, []);
  return (
    <div className="Dashboard">
      <UserTable userData={data} />
    </div>
  );
}

export default Users;

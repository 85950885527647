import { LOGGED_IN, LOGOUT } from "./types";

const initialState = {
  auth: {
    uid: null,
    name: null,
    permission: [],
  },
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        auth: {
          uid: action.payload.uid,
          name: action.payload.name,
          permission: action.payload.permission,
        },
      };
    case LOGOUT:
      return {
        ...state,
        auth: {
          uid: null,
          name: null,
          permission: [],
        },
      };
    default:
      return state;
  }
};

export default userReducer;

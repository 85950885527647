import React from "react";
import { Fade } from "react-awesome-reveal";

import "./Intro1.css";

function Intro(props) {
  return (
    <div className="item" id="first">
      <section className="intro">
        <div className="centerBox">
          <div className="rightArea">
            {/* <div className="infoOrder text"> */}
            {/* <span className="orderBg"></span> */}
            {/* <span className="orderNumber">01</span> */}
            {/* </div> */}
            <Fade cascade direction="up">
              <h3 className="infoTitle text">
                주유를{" "}
                <b style={{ color: "#fabe00", fontWeight: "bold" }}>간편</b>하게
                <br />
                운전을{" "}
                <b style={{ color: "#fabe00", fontWeight: "bold" }}>편리</b>하게
              </h3>

              <p className="infoText text">
                주유, 세차, 케미칼, 디지털키를 하나로!
                <br />
                스타코엑스에서 다양한 기능을 경험해 보세요.
              </p>
            </Fade>
          </div>
          <div className="slideArea text"></div>
        </div>
      </section>
    </div>
  );
}

export default Intro;

import { combineReducers } from "redux";
import userReducer from "./user/reducer";
// import viewReducer from "./views/reducer";
// import commentsReducer from "./comments/reducer";

const RootReducer = combineReducers({
  user: userReducer,
  // views: viewReducer,
  // comments: commentsReducer,
});

export default RootReducer;

import React, { useEffect, useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { db, formatDate } from "../../firebase";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import "./index.css";
import { useHistory } from "react-router-dom";

function Dashboard({ match }) {
  const [payment, setpayment] = useState({ today: 0, yesterday: 0 });
  const [userCount, setuserCount] = useState({ today: 0, yesterday: 0 });
  const [memberCount, setmemberCount] = useState({ star: 0, key: 0, Pkey: 0 });

  const history = useHistory();

  function getUserCount() {
    const today = new Date(formatDate()) - 32400000;
    const yesterday = today - 86400000;
    db.collection("user")
      .where("created", ">=", yesterday)
      .get()
      .then(snap => {
        console.log(snap.size);
        let afterYesterday = snap.size;
        let afterToday = 0;
        snap.forEach(doc => {
          if (doc.data().created >= today) {
            afterToday += 1;
          }
        });

        console.log("usr:", { today: afterToday, yesterday: afterYesterday - afterToday });
        setuserCount({ today: afterToday, yesterday: afterYesterday - afterToday });

        // setuserCount(snap.size);
      });
  }

  function getMembership() {
    let star = 0;
    let key = 0;
    let Pkey = 0;
    db.collection("user")
      .where("membership", "array-contains", true)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let type = doc.data().membership[1];
          if (type !== undefined) {
            switch (type) {
              case "star":
                star += 1;
                break;

              case "key":
                key += 1;
                break;

              case "Pkey":
                Pkey += 1;
                break;

              default:
                break;
            }
            // console.log(star, key, Pkey);
            // type === "star" ? (star += 1) : (key += 1);
          }
        });
        setmemberCount({ star: star, key: key, Pkey: Pkey });
      });
  }

  function totalpayment() {
    const today = new Date(formatDate()) - 32400000;
    const yesterday = today - 86400000;
    console.log(today / 1000, yesterday / 1000);
    db.collection("payment")
      .where("started_at", ">=", yesterday / 1000)
      .get()
      .then(snap => {
        let afterYesterday = 0;
        let afterToday = 0;
        snap.forEach(doc => {
          if (doc.data().status === "paid") {
            afterYesterday += doc.data().amount;
            // console.log("어제:", afterYesterday);
            if (doc.data().started_at >= today / 1000) {
              afterToday += doc.data().amount;
              // console.log("오늘", afterToday);
            }
          }
        });

        setpayment({ today: afterToday, yesterday: afterYesterday - afterToday });
      });
  }

  useEffect(() => {
    getUserCount();
    getMembership();
    totalpayment();
    return () => {};
  }, []);
  return (
    <div className="Dashboard">
      <CardColumns>
        <Card
          className="dashCard"
          onClick={() => {
            history.push("/adm/payments");
          }}>
          <Card.Body className="text-center">
            <Card.Title>당일 매출액</Card.Title>
            <Card.Text>{payment.today.toLocaleString()}원</Card.Text>
            <Card.Text>
              {payment.today - payment.yesterday < 0 && (
                <small id="down">
                  전일 대비 {Math.round(((payment.today - payment.yesterday) / payment.yesterday) * 100)}%▾
                  <br />({payment.yesterday.toLocaleString()}원)
                </small>
              )}
              {payment.today - payment.yesterday > 0 && (
                <small id="up">
                  전일 대비 {Math.round(((payment.today - payment.yesterday) / payment.yesterday) * 100)}%▴
                  <br />({payment.yesterday.toLocaleString()}원)
                </small>
              )}
              {payment.today - payment.yesterday === 0 && <small id="same">전일 대비 -%</small>}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card
          className="dashCard"
          onClick={() => {
            history.push("/adm/users");
          }}>
          <Card.Body className="text-center">
            <Card.Title>당일 가입자 수</Card.Title>
            <Card.Text>{userCount.today}명</Card.Text>
            <Card.Text>
              {userCount.today - userCount.yesterday < 0 && (
                <small id="down">
                  전일 대비 {Math.abs(userCount.today - userCount.yesterday)}명 감소▾
                  <br />({userCount.yesterday}명)
                </small>
              )}
              {userCount.today - userCount.yesterday > 0 && (
                <small id="up">
                  전일 대비 {Math.abs(userCount.today - userCount.yesterday)}명 증가▴ <br />({userCount.yesterday}명)
                </small>
              )}
              {userCount.today - userCount.yesterday === 0 && (
                <small id="same">
                  전일 대비 -명 <br />({userCount.yesterday}명)
                </small>
              )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card
          className="dashCard"
          onClick={() => {
            history.push("/adm/membership");
          }}>
          <Card.Body className="text-center">
            <Card.Title>멤버십회원 수 {memberCount.star + memberCount.key}명</Card.Title>
            <ResponsiveContainer width="100%" minHeight="220px">
              <BarChart
                width={100}
                height={200}
                barSize={60}
                barGap={20}
                data={[{ name: "회원 수", 스타: memberCount.star, 디지털키: memberCount.key, 프리미엄: memberCount.Pkey }]}
                margin={{
                  top: 5,
                  right: 30,
                  left: -15,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="스타" fill="#FF9600" />
                <Bar dataKey="디지털키" fill="#506079" />
                <Bar dataKey="프리미엄" fill="#514183" />
              </BarChart>
            </ResponsiveContainer>
          </Card.Body>
        </Card>
      </CardColumns>
      {/* <div>
        <Card className="dashCard">
          <Card.Body className="text-center">
            <Card.Title>멤버십 회원 수</Card.Title>
            <Card.Text>12 명</Card.Text>
            <Card.Text>
              <small id="up">전월 대비 10% 증가▴</small>
            </Card.Text>
          </Card.Body>
        </Card>
      </div> */}
    </div>
  );
}

export default Dashboard;

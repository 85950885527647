import React from "react";

import "./Terms.css";

function Terms(props) {
  return (
    <div id="termsWrap" className="terms_wrap">
      <header id="header">
        <div className="head">
          <h1 className="billing"> 스타코엑스 정기결제 이용약관 </h1>
        </div>
      </header>

      <main id="kakaoContent">
        <div class="cont_agreement">
          <h3 class="tit_cate">제1장 총칙</h3>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 1 조 (목적)</h4>
              <p class="desc_agree">
                본 약관은 (주)스타코엑스(이하 "회사"라 한다)가 제공하는 모바일 어플리케이션 서비스(이하 "서비스"라 한다) 내에서 유료서비스의 이용과
                관련하여 "회사"와 "회원"의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 2 조 (약관의 적용)</h4>
              <p class="list_mini">
                본 약관은 "회원"이 서비스 내에서 유료로 제공되는 서비스를 이용하고자 하는 경우, 본 약관에 별도로 동의한 회원에게 적용됩니다.
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 3 조 (정의)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span> 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
                  <ul class="list_mini">
                    <li>
                      1. "회원"이라 함은 본 약관에 따라 "회사"와 이용계약을 체결하여 "회사" 또는 판매자가 제공하는 유료서비스를 이용하는 자를
                      말합니다.
                    </li>
                    <li>
                      2. "유료서비스"라 함은 "회사" 또는 “판매자”가 유료로 제공하는 각종 서비스, 온라인 디지털콘텐츠 및 이를 결제 이용하는 데에
                      수반되는 제반 관련 서비스를 말합니다.
                    </li>
                    <li>
                      3. “정기결제형 유료서비스”라 함은 “유료서비스” 중 “정기결제” 형태로 결제가 이루어지고 이용 기간이 자동으로 갱신되는 서비스를
                      말합니다.
                    </li>
                    <li>
                      4. “정기결제”라 함은 “유료서비스”를 일정 시기 또는 조건 하에서 반복적으로 거래하기 위하여 미리 등록한 결제수단으로 특정일 또는
                      특정 조건에 도달 시 자동으로 결제하는 결제 형태를 말합니다.
                    </li>
                    <li>
                      6. "판매자"라 함은 "회사"와 별도의 계약을 체결하고 "회사"의 “유료서비스”를 이용하여 "회원"에게 각종 온라인 디지털콘텐츠를
                      판매하고자 하는 자를 말합니다.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>본 약관에 사용되는 용어 중 본 조에서 정하지 아니한 부분은 관계 법령 및 일반 관례에 따릅니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 4 조 (신원정보 등의 제공)</h4>
              <p class="desc_agree">
                "회사"는 본 약관의 내용, 상호, 대표자 성명, 전화번호, 팩스번호, 사업자등록번호, 통신판매업 신고번호, 고객센터 등을 "회원"이 쉽게 알 수
                있도록 “유료서비스” 화면에 순차적으로 게시합니다. 다만, 대표자성명, 팩스번호, 사업자등록번호 및 본 약관은 "회원"이 “유료서비스”
                화면에서 순차적으로 연결된 화면을 통하여 볼 수 있도록 할 수 있습니다.
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 5 조 (약관의 게시, 개정 등)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 본 약관의 내용을 "회원"이 쉽게 확인할 수 있도록 서비스 초기화면 또는 별도의 연결화면에
                  게시합니다.{" "}
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 "회원"이 본 약관의 내용을 확인할 수 있도록 전문을 별도의 연결화면 또는 팝업화면 등을 통해
                  제공합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등
                  관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 "회사"가 운영하는
                  웹사이트 내의 적절한 장소에 개정약관의 적용일자 15일 전부터 적용일 이후 상당한 기간 동안 공지합니다. 다만, "회원"에게 불리한 약관의
                  개정의 경우에는 적용일자 30일 전부터 공지하고, "회원"이 가입시 제공한 핸드폰번호, 서비스 내 푸시알림, 팝업 화면 등의 전자적 수단을
                  통해 통지합니다.
                </li>
                <li>
                  <span class="txt_num">⑤</span>"회사"가 전항에 따라 개정약관을 공지 또는 통지하면서 "회원"에게 약관 변경 적용일까지 거부의사를
                  표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이 명시적으로 거부의
                  의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.
                </li>
                <li>
                  <span class="txt_num">⑥</span>"회원"이 개정약관의 적용에 동의하지 않는 경우 "회사"는 개정 약관의 내용을 적용할 수 없으며, 이 경우
                  "회원"은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 "회사"는 이용계약을 해지할 수
                  있습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 6 조 (회원에 대한 통지)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"가 "회원"에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 "회원"이 가입시 제공한
                  핸드폰번호, 서비스 내 푸시알림, 팝업 화면 등으로 할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"가 운영하는 서비스 내의 게시판 등에 게시,
                  공지함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, "회원" 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의
                  방법으로 통지를 합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 "회원"의 연락처 미기재, 변경 후 미수정 등으로 인하여 개별 통지가 어려운 경우에 한하여 제2항의
                  공지로써 개별 통지를 한 것으로 간주합니다.
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제2장 이용계약 체결 및 유료서비스 이용</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 7 조 (유료서비스의 내용 등의 게시)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 다음 사항을 해당 “유료서비스”의 이용초기화면이나 그 포장, FAQ 등에 "회원"이 알기 쉽게
                  표시합니다.
                  <ul class="list_mini">
                    <li>1. “유료서비스”의 명칭 또는 제호</li>
                    <li>2. “유료서비스” 제작/제공자의 성명(법인인 경우에는 법인의 명칭), 주소, 전화번호</li>
                    <li>3. “유료서비스”의 내용, 이용방법, 이용료 기타 이용조건</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"의 “유료서비스”의 이용가능기기 및 이용에 필요한 최소한의 기술사양은 권장사양정보에 따릅니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 “유료서비스”를 제공함에 있어 “유료서비스”의 교환·반품·보증과 그 대금 환불의 조건 및 절차에
                  관한 사항을 제공합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 8 조 (이용계약의 성립 등)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회원"은 "회사"가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. "회사"는 계약 체결
                  전에 각 호의 사항에 관하여 "회원"이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.
                  <ul class="list_mini">
                    <li>1. “유료서비스”의 확인 및 선택</li>
                    <li>2. 결제방법의 선택 및 결제정보의 입력</li>
                    <li>3. “유료서비스”의 이용신청에 관한 확인 또는 "회사"의 확인에 대한 동의</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 "회원"의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.
                  <ul class="list_mini">
                    <li>1. 실명이 아니거나 타인의 명의를 이용한 경우</li>
                    <li>2. 허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우</li>
                    <li>3. 미성년자가 청소년보호법에 의해서 이용이 금지되는 유료서비스를 이용하고자 하는 경우</li>
                    <li>4. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우</li>
                    <li>5. 무료체험 기간 또는 서비스이용 유예기간 등 회사에서 제공하는 혜택을 남용하기 위한 재가입이라고 판단되는 경우</li>
                    <li>7. 기타 "회원"의 귀책사유로 승인이 불가능하다고 판단되는 경우</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">③</span>이용계약의 성립시기는 ‘가입완료’ 또는 ‘구매완료’를 신청절차 상에서 표시한 시점으로 합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회원"이 “유료서비스”를 이용하기 위해서는 본 약관에 동의한 후 해당 “유료서비스”의 이용조건에 따라
                  이용요금을 지급하여야 합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 9 조 (결제수단)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>“유료서비스”에서 사용할 수 있는 이용요금 결제수단은 다음 각 호와 같습니다. 다만, “회사”는 “회원”이
                  선택한 결제수단에 대하여 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.
                  <ul class="list_mini">
                    <li>1. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                    <li>2. 기타 전자적 지급방법에 의한 대금지급 등</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>“회원”은 “유료서비스” 이용을 위하여 해당 서비스에 결제수단 및 결제 정보를 입력하고 이를 저장할 수
                  있습니다. 저장된 결제수단 및 결제 정보는 정기결제나 다음 결제 시에 사용됩니다.
                </li>
                <li>
                  <span class="txt_num">③</span>“회원”은 타인의 결제수단을 임의로 사용하여서는 안됩니다. 타인의 결제수단을 임의 사용함으로써 발생하는
                  회사, 결제수단의 적법한 소유자, 기타 해당 결제와 관련된 제3자의 손실이나 손해에 대한 책임은 “회원”에게 있습니다.
                </li>
                <li>
                  <span class="txt_num">④</span>“유료서비스” 이용을 위한 결제와 관련하여 “회원”이 입력한 정보와 관련하여 발생한 문제에 대한 책임과
                  불이익은 “회원”이 부담하여야 합니다.
                </li>
                <li>
                  <span class="txt_num">⑤</span>“회원”은 “유료서비스” 이용요금 결제 시 정당하고, 적법한 사용권한을 가지고 있는 결제수단을 사용하여야
                  하며, “회사”는 그 여부를 확인할 수 있습니다. 또한 “회사”는 “회원”이 사용한 결제수단의 적법성 등에 대한 확인이 완료될 때까지 거래
                  진행을 중지하거나 해당 거래를 취소할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">⑥</span>"회사"의 정책 및 제1항의 결제수단을 제공하는 업체(이동통신사, 카드사 등)의 기준이나 법령에 따라 "회원"
                  당 월 누적 결제액 및 충전한도가 제한될 수 있습니다. 해당 기준을 초과한 경우 유료서비스의 추가 이용이 불가능할 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 10 조 (정기결제 및 정기결제형 유료서비스의 제공)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>“회원”은 “정기결제형 유료서비스”를 이용하기 위하여 제9조 제2항에 따라 해당 서비스에 결제수단 및 결제
                  정보를 입력하고 저장하여야 하며, 그에 따라 이용요금이 정기결제일(“정기결제형 유료서비스”의 최초 결제일 또는 이용자가 임의로 지정한
                  날짜를 의미하며, 이하 동일합니다)에 자동으로 결제됩니다. 단, 특정 월에 정기결제일에 해당하는 날짜가 존재하지 않을 경우 해당 월에
                  한하여 말일에 결제가 진행됩니다.
                </li>
                <li>
                  <span class="txt_num">②</span>제1항에도 불구하고, “회원”이 2개 이상의 “정기결제형 유료서비스”를 이용하는 경우, 최초 결제한
                  “정기결제형 유료서비스”의 결제일(이하 “최초 결제일”이라 합니다)에 “회원”이 이용하는 모든 “정기결제형 유료서비스”의 이용요금이 통합
                  결제될 수 있습니다. 이 때 나중에 이용을 개시한 “정기결제형 유료서비스”의 첫 달 이용요금은 해당 서비스의 이용 개시일자부터 다음번
                  “최초 결제일” 전 날까지의 비용만 일할계산하여 청구되며, 도래하는 “최초 결제일”부터 정상 요금이 통합 과금됩니다.
                </li>
                <li>
                  <span class="txt_num">③</span>“회사”는 정기결제가 이루어지면 회원에게 결제 내용을 제6조에 따른 방법으로 고지합니다. 단, 회원의
                  명시적 동의가 있는 경우 회사는 결제 내용에 대한 고지를 생략할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">④</span>등록된 결제수단의 유효기간 만료 등 여하의 사유로 정기결제일에 이용요금의 결제가 정상적으로 이루어지지
                  않는 경우 “정기결제 유료서비스”의 이용이 중단될 수 있으며, 이러한 상황이 지속되는 경우 채무이행의 거절로 보고 “회사”가 “유료서비스”
                  이용계약을 해지할 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 11 조 (미성년자 이용계약에 관한 특칙)</h4>
              <p class="desc_agree">
                "회사"는 만 19세 미만의 미성년 회원이 “유료서비스”를 이용하고자 하는 경우에 부모 등 법정대리인의 동의를 얻거나, 계약체결 후 추인을
                얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지합니다.
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 12 조 (유료서비스의 제공 및 중단)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 “유료서비스”를 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우
                  유료서비스의 제공을 일시적으로 중단할 수 있고, 이 경우 "회사"는 제6조에서 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에
                  통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 “유료서비스”의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 “유료서비스”
                  제공 화면에 공지한 바에 따릅니다.
                </li>
                <li>
                  <span class="txt_num">④</span>사업종목의 전환, 사업의 포기, 분할·합병 등의 이유로 유료서비스를 제공할 수 없게 되는 경우에 "회사"는
                  제6조에서 정한 방법으로 "회원"에게 통지하고 "회사"는 합리적 조건에 따라 "회원"에게 보상합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 13 조 (유료서비스의 변경)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의
                  “유료서비스”를 변경할 수 있습니다. 다만, 변경된 내용이 중대하거나 "회원"에게 불리한 경우에는 "회사"가 해당 “유료서비스”를 제공받는
                  "회원"에게 제6조에서 정한 방법으로 통지합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 전항에 따른 서비스 변경에 대한 동의를 거절한 "회원"에 대하여는 변경 전 서비스를 제공합니다.
                  다만, 그러한 서비스 제공이 불가능할 경우 해당 서비스의 제공을 중지하거나 계약을 해지할 수 있습니다. 이 경우 환불 등은 제17조에서
                  정한 바에 따라 진행됩니다.
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제3장 계약당사자의 의무</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 14 조 (회사의 의무)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로
                  “유료서비스”를 제공하기 위하여 최선을 다합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 "회원"이 안전하게 “유료서비스”를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위한
                  보안시스템을 갖추며, 개인정보처리방침을 공지하고 이를 준수합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 “유료서비스” 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만사항이 정당하다고 인정되는
                  경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 "회원"에게 처리 과정 및 결과를 전달합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>“회사”는 전자상거래등에서의 소비자보호에 관한 법률에 따라 아래 각 호의 항목을 각 호에 명시된 기간동안
                  보관합니다.
                  <ul class="list_mini">
                    <li>1. 계약 또는 청약철회 등에 관한 기록: 5년</li>
                    <li>2. 대금결제 및 재화 등에 공급에 관한 기록: 5년</li>
                    <li>3. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 15 조 (회원의 의무)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회원"은 “유료서비스” 이용 전에 반드시 "회사" 또는 “판매자”가 제공하는 “유료서비스”의 상세 내용과
                  거래의 조건을 정확하게 확인한 후 구매를 하여야 합니다. 거래의 내용과 조건을 확인하지 않고 구매하여 발생한 손실, 손해는 "회원"에게
                  귀속됩니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회원"은 본 약관 및 "회사"가 “유료서비스”와 관련하여 고지하는 내용을 준수하여야 하며, 본 약관 및 고지
                  내용을 위반하거나 이행하지 아니하여 발생하는 손실, 손해에 대한 책임을 부담합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회원"은 "회사"가 정하거나 인정하는 방식에 따라 마일리지, 쿠폰을 사용하여야 합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회원"은 “유료서비스”를 이용할 때 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
                  <ul class="list_mini">
                    <li>
                      1. "회사"가 제공하는 “유료서비스” 이용방법에 의하지 아니하고 비정상적인 방법으로 “유료서비스”를 이용하거나 시스템에 접근하는
                      행위
                    </li>
                    <li>2. 타인의 명의, 카드정보, 계좌정보 등을 도용하여 "회사"가 제공하는 “유료서비스”를 이용하는 행위</li>
                    <li>3. "회사"가 정하지 않은 비정상적인 방법으로 마일리지를 취득하거나 사용하는 행위</li>
                    <li>4. "회사"가 게시한 정보의 무단 변경 또는 "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시하는 행위</li>
                    <li>5. "회사" 및 기타 제3자의 저작권 등 지적재산권에 대한 침해행위</li>
                    <li>6. "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                    <li>7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “유료서비스”에 공개 또는 게시하는 행위</li>
                    <li>8. 구매의사가 없는 반복적인 구매행위</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제4장 청약철회, 계약해지 및 이용제한 등</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 16 조 (청약철회)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>“회원”은 본 약관에 따라 “유료서비스”를 이용하기 위해 유료 결제를 한 날로부터 7일 이내에 회사에 대해
                  서비스의 이용에 관한 청약을 철회할 수 있습니다. 다만, 회사가 회원에게 사전에 합의한 내용과 다르게 서비스를 제공하거나 서비스를
                  제공하지 아니하는 경우, 회원은 유료 결제를 한 날로부터 3개월 이내 또는 해당 사실을 알거나 알 수 있었던 날로부터 30일 이내에 회사에
                  대해 서비스의 이용에 관한 청약을 철회할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">②</span>제1항에도 불구하고, “회원”은 다음 각 호의 어느 하나에 해당하는 경우에는 청약철회를 할 수 없습니다.
                  <ul class="list_mini">
                    <li>
                      1. 콘텐츠의 내용을 확인하기 위하여 포장 등을 훼손한 경우를 제외하고, 이용자에게 책임 있는 사유로 콘텐츠가 멸실 또는 훼손된 경우
                    </li>
                    <li>2. 이용자의 사용 또는 일부 소비에 의하여 콘텐츠의 가치가 현저히 감소한 경우</li>
                    <li>3. 시간의 경과에 의하여 재판매가 곤란할 정도로 콘텐츠의 가치가 현저히 감소한 경우</li>
                    <li>4. 복제가 가능한 콘텐츠의 포장을 훼손한 경우</li>
                    <li>
                      5. 이용자의 주문에 의하여 콘텐츠가 개별적으로 생산되는 경우 등 청약철회등을 인정하면 사업자에게 회복할 수 없는 중대한 피해가
                      예상되는 경우로서 사전에 당해 거래에 대하여 별도로 그 사실을 고지하고 이용자의 서면(전자문서를 포함한다)에 의한 동의를 얻은 경우
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">③</span>“회사”는 청약철회가 불가능한 서비스나 콘텐츠에 대해서는 다음 각 호의 조치를 취합니다.
                  <ul class="list_mini">
                    <li>1. 청약철회가 불가능한 “유료서비스”에 해당한다는 사실을 표시사항에 포함</li>
                    <li>2. 시용상품을 제공하거나 한시적 또는 일부 이용 등의 방법을 제공</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">④</span>청약철회 및 환불 요청은 각 서비스에서 제공하는 메뉴 및 고객센터에서 할 수 있으며, 소비자의
                  청약철회일로부터 3 영업일 이내에 지급받은 대금의 환급을 정당한 사유 없이 지연하는 경우 소비자는 지연기간에 대해서 연 15%(콘텐츠의
                  경우에는 연 20%)의 지연 배상금을 회사에게 청구할 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 17 조 (회원의 이용계약 해제, 해지)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회원"은 다음 각 호의 사유가 있을 때 이용계약을 해지 또는 해제할 수 있습니다.
                  <ul class="list_mini">
                    <li>1. “유료서비스”의 하자를 "회사"가 보완, 수정할 수 없는 경우 “유료서비스”를 공급받은 날로부터 1개월 이내</li>
                    <li>
                      2. “유료서비스” 이용 "회원"이 본 약관 또는 스타코엑스 이용약관의 개정, 변경에 동의하지 않아 회원 탈퇴를 하거나 “유료서비스”
                      이용계약을 해지하는 경우
                    </li>
                    <li>3. “방문판매 등에 관한 법률”에 따른 계속거래에 해당하는 “유료서비스”의 이용계약을 해지하는 경우</li>
                    <li>4. 기타 관련 법령이나 본 약관에 따라 “회원”이 “유료서비스” 이용계약을 해지 또는 해제하고자 하는 경우</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>제1항의 계약해제, 해지는 "회원"이 전화, 전자우편 등으로 "회사"에 그 의사를 표시한 때에 효력이
                  발생합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 제2항에 따라 "회원"이 표시한 계약해제, 해지의 의사표시를 수신한 후 지체 없이 이러한 사실을
                  "회원"에게 회신합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회원"은 제1항 제1호의 사유로 계약해제, 해지의 의사표시를 하기 전에 상당한 기간을 정하여 서비스이용의
                  하자에 대한 치유를 요구할 수 있습니다. 다만, "회사"가 하자의 치유가 불가능하다고 판단하는 경우에는 즉시 그 사실을 "회원"에게
                  통지하고 이용계약을 해제, 해지할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">⑤</span>"회사"는 "회원"에게 계약해제, 해지의 의사표시에 대하여 회신한 날로부터 3영업일 이내에 대금의 결제와
                  동일한 방법으로 이를 환급하여야 하며, 동일한 방법으로 환불이 불가능할 때에는 계좌 이체 혹은 마일리지로 적립하는 등의 방법으로 환불
                  조치할 수 있으며 이 경우 “회원”에게 고지하여야 합니다. 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터 3영업일 이내에
                  이를 환급하도록 합니다.
                </li>
                <li>
                  <span class="txt_num">⑥</span>"회사"가 제5항에 따라 환급할 경우에 “회사”는 이용요금 일할 계산 등의 방법으로 "회원"이 “유료서비스”
                  이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환급할 수 있습니다.{" "}
                </li>
                <li>
                  <span class="txt_num">⑦</span>"회사"는 위 대금을 환급함에 있어서 "회원"이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을
                  지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제5항
                  단서 및 제6항의 경우에는 그러하지 아니할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">⑧</span>"회사", “유료서비스” 등의 대금을 지급 받은 자 또는 "회원"과 “유료서비스” 이용계약을 체결한 자가
                  동일인이 아닌 경우에 각자는 청약철회 또는 계약해제, 해지로 인한 대금환급과 관련한 의무의 이행에 있어서 연대하여 책임을 집니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 18 조 (회사의 계약해제, 해지 및 이용제한)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 "회원"이 스타코엑스 이용약관 또는 본 약관에 명시된 "회원"의 의무를 위반하였을 경우, 또는
                  그러한 위법·부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우 “회원”과의 “유료서비스” 이용계약을 해제, 해지하거나 또는
                  기간을 정하여 “유료서비스”의 이용을 제한할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">②</span>제1항의 해제, 해지는 "회사"가 자신이 정한 통지 방법에 따라 "회원"에게 그 의사를 표시한 때에 효력이
                  발생합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"의 해제, 해지 및 이용제한에 대하여 "회원"은 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다.
                  이 때 이의가 정당하다고 "회사"가 인정하는 경우, "회사"는 즉시 서비스의 이용을 재개합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회사"는 "회원"에 대하여 계약해제, 해지의 의사표시를 한 날로부터 3영업일 이내에 대금의 결제와 동일한
                  방법으로 “유료서비스” 이용요금을 환급합니다. 다만, 동일한 방법으로 환불이 불가한 경우에는 계좌 이체 혹은 마일리지를 적립하는 등의
                  방법으로 환불 조치할 수 있으며 “회사”는 이를 “회원”에게 고지합니다. 이 때, “회사”는 이용요금 일할 계산 등의 방법으로 "회원"이
                  “유료서비스” 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환급할 수 있습니다.
                </li>
                <li>
                  <span class="txt_num">⑤</span>"회사"의 계약해제, 해지가 "회원"의 귀책사유에 따른 경우 "회사"는 "회원"이 “유료서비스” 이용으로부터
                  얻은 이익 및 환불수수료(10% 또는 1,000원 중 큰 금액)에 해당하는 금액을 공제하고 유료서비스 이용요금을 환급할 수 있습니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 class="tit_agree">제 19 조 (과오금)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 과오금이 발생한 경우 이용요금의 결제와 동일한 방법으로 과오금 전액을 환불하여야 합니다. 다만,
                  동일한 방법으로 환불이 불가능할 때는 계좌 이체 혹은 마일리지로 적립하는 등의 방법으로 환불 조치할 수 있으며 이 경우 “회원”에게
                  사전에 고지합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"의 책임 있는 사유로 과오금이 발생한 경우 "회사"는 계약비용, 수수료 등에 관계없이 과오금 전액을
                  환불합니다. 다만, "회원"의 책임 있는 사유로 과오금이 발생한 경우, "회사"가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서
                  "회원"이 부담하여야 합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 "회원"이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용요금이 부과되었음을 입증할
                  책임을 집니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회사"는 과오금의 환불절차를 관련 법령에 따라 처리합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 20 조 (소멸시효)</h4>
              <p class="desc_agree">
                마일리지, 쿠폰의 경우는 마지막 이용일로부터 5년 경과 시까지 이용하지 않을 경우 상사소멸시효에 따라 소멸될 수 있습니다. 다만, 무상으로
                제공하는 마일리지, 쿠폰 경우는 "회사"의 정책에 따라 소멸될 수 있습니다.
              </p>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제5장 중개형 유료서비스 이용 특칙</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제21조 (대리행위 및 보증의 부인)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 "회원"과 “판매자” 간 거래를 중개하는 유료서비스(이하 "중개형 유료서비스"라 한다)에서는 거래를
                  위한 시스템을 운영·관리·제공할 뿐이며, "회원" 및 “판매자”를 대리하지 않습니다. 또한, "회원"과 “판매자” 사이에 성립된 거래 및 이에
                  따라 "회원" 또는 “판매자”가 제공한 정보에 대한 책임은 "회원" 및 판매자가 직접 부담합니다. 다만, "회사"가 "회원"에게 직접 판매한
                  경우에는 "회사"가 판매 책임을 다합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 “중개형 유료서비스”를 통하여 이루어지는 "회원"과 “판매자” 간 거래와 관련하여 판매의사 또는
                  구매의사의 존부 및 진정성, 거래 대상의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, "회원" 또는 “판매자”가 제공한
                  정보 및 그 정보의 진실성 또는 적법성 등 일체에 대하여 보증하지 않습니다.
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제6장 기타</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제22조 (책임제한)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회사"는 관련 법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 “유료서비스”를 제공할 수 없는
                  경우에는 “유료서비스” 제공에 관한 책임이 면제됩니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회사"는 "회원"의 귀책사유로 인한 “유료서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회사"는 "회원"이 “유료서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에
                  관하여는 책임을 지지 않습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제23조 (약관의 해석)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정보통신망 이용촉진 및 정보보호에 관한
                  법률, 콘텐츠산업진흥법, 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 문화체육관광부장관이 정하는 디지털콘텐츠
                  이용자보호지침, 기타 관련 법령, 스타코엑스 이용약관 및 상관례에 따릅니다.
                </li>
                <li>
                  <span class="txt_num">②</span>본 약관 외에 개별 “유료서비스”에 대해 적용되는 별도 약관(이하 “개별 서비스 약관”이라 합니다)이 있을
                  경우, 본 약관의 내용과 “개별 서비스 약관”의 내용 간 충돌에 대해서는 달리 정하지 않는 한 해당 “개별 서비스 약관”이 우선합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제24조 (준거법 및 재판관할)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>본 약관과 관련된 사항에 대하여는 대한민국법을 준거법으로 합니다.
                </li>
                <li>
                  <span class="txt_num">②</span>“회사”와 “회원” 간에 발생한 분쟁에 대해서는 상호 신의성실에 바탕한 협의로 해결하도록 하되, 상호 협의가
                  어려울 경우 민사소송법 상의 관할법원에 제소하여 분쟁을 해결합니다.
                </li>
              </ul>
            </li>
            <li>
              <h3 class="tit_cate">부칙</h3>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>본 약관은 2021년 1월 18일부터 적용됩니다. 단, 본 약관의 공지 시점으로부터 적용일 전일까지의 기간
                  동안에 가입한 신규회원에 대해서는 회원 가입시부터 본 약관이 적용됩니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </main>
    </div>
  );
}

export default Terms;

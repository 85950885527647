import React from "react";
import { useHistory } from "react-router-dom";
import "./Sidebar.css";
import { SidebarData } from "./SidebarData";

// import ToggleOff from "@material-ui/icons/ToggleOff";
// import ToggleOn from "@material-ui/icons/ToggleOn";
function Sidebar({ match }) {
  const history = useHistory();
  // const [sideToggle, setsideToggle] = React.useState(true);
  const sideRef = React.useRef();

  // const handleToggle = () => {
  //   if (sideToggle) {
  //     setsideToggle(false);
  //     console.log(sideRef);
  //     debugger
  //   } else {
  //     setsideToggle(true);
  //   }
  // };

  return (
    <div className="Sidebar" id="sidebar" ref={sideRef}>
      {/* <div onClick={handleToggle} className="toggleBtn">
        {sideToggle ? <ToggleOn /> : <ToggleOff />}
      </div> */}
      {/* <SettingsIcon className="toggle" onClick={closeNav} /> */}
      <ul className="SidebarList">
        {SidebarData.map((val, key) => {
          return (
            <li
              key={key}
              className="row"
              id={window.location.pathname === val.link ? "active" : ""}
              onClick={() => {
                history.push(val.link);
              }}>
              <div id="icon">{val.icon}</div>
              <div id="title">{val.title}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;

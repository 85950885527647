import React from "react";
import { Fade } from "react-awesome-reveal";

import "./Footer.css";

function Footer() {
  return (
    <footer className="footer" id="footer">
      <Fade cascade direction="up">
        <p className="downloadTipPc text">
          더 다양한 혜택이 궁금하다면?
          <br />
          <b style={{ fontWeight: "bold" }}>스타코엑스</b> 바로가기
        </p>
      </Fade>

      <Fade cascade direction="up">
        <div className="mobileArea">
          <h3 className="tipTitle text">더 다양한 혜택이 궁금하다면?</h3>
          <p className="downloadTip text">스타코엑스</p>
        </div>
      </Fade>

      <div className="downloadArea">
        <Fade delay={500}>
          <a
            href="http://onelink.to/rh85f7"
            className="downloadBtn googleAppBtn"
          >
            <img
              src="https://apps.onoffmix.com/img/downloadAppGoogleBlack.png"
              alt="안드로이드 다운로드"
            />
            <h4>다운로드</h4>
          </a>
          <a href="http://onelink.to/rh85f7" className="downloadBtn iosAppBtn">
            <img
              src="https://apps.onoffmix.com/img/downloadAppIosBlack.png"
              alt="ios 다운로드"
            />
            <h4>다운로드</h4>
          </a>
        </Fade>
      </div>
      <p className="copyright">copyright © STARCOEX, All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;

import { createStore, applyMiddleware } from "redux";
import RootReducer from "./rootReducer";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const middleware = [logger, thunk];
const store = createStore(RootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;

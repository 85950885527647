import React from "react";
import QnaTable from "../../components/QnaTable";
import "./Qna.css";

function Qna() {
  return (
    <div className="Dashboard">
      <QnaTable />
      {/* <div className="qna_board">
        <div className="MTableToolbar-title-9">
          <h6 className="MuiTypography-root MuiTypography-h6" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            문의사항
          </h6>
        </div>
      </div> */}
    </div>
  );
}

export default Qna;

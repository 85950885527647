import React from "react";

import "./Policy.css";

function Policy(props) {
  return (
    <div id="termsWrap" className="terms_wrap">
      <header id="header">
        <div className="head">
          <h1 className="billing"> 스타코엑스 이용약관 </h1>
        </div>
      </header>

      <main>
        <div class="cont_agreement">
          <h3 class="tit_cate">제1장 총칙</h3>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 1 조 (목적)</h4>
              <p class="desc_agree">
                본 약관은 (주)스타코엑스(이하 "회사"라 한다)가 제공하는 모바일
                어플리케이션 서비스(이하 "서비스"라 한다) 내에서 제공하는 모든
                서비스(이하 "서비스"라 한다)의 이용조건 및 절차에 관한 사항을
                규정함을 목적으로 합니다.
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 2 조 (정의)</h4>
              <p class="desc_agree">
                <p>이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</p>
                <p>
                  1. 이용자 : 본 약관에 따라 회사가 제공하는 서비스를 받는 자
                </p>
                <p>
                  2. 이용계약 : 서비스 이용과 관련하여 회사와 이용자간에
                  체결하는 계약
                </p>
                <p>
                  3. 가입 : 회사가 제공하는 신청서 양식에 해당 정보를 기입하고,
                  본 약관에 동의하여 서비스 이용계약을 완료시키는 행위
                </p>
                <p>
                  4. 회원 : 당 사이트에 회원가입에 필요한 개인정보를 제공하여
                  회원 등록을 한 자
                </p>
                <p>
                  5. 이용자번호(ID) : 회원 식별과 회원의 서비스 이용을 위하여
                  이용자가 선정하고 회사가 승인하는 영문자와 숫자의 조합(하나의
                  주민등록번호에 하나의 ID만 발급 가능함)
                </p>
                <p>
                  6. 패스워드(PASSWORD) : 회원의 정보 보호를 위해 이용자 자신이
                  설정한 영문자와 숫자, 특수문자의 조합
                </p>
                <p>
                  7. 이용해지 : 회사 또는 회원이 서비스 이용이후 그 이용계약을
                  종료시키는 의사표시
                </p>
              </p>
            </li>
            <li>
              <h4 class="tit_agree">제 3 조 (약관의 효력과 변경)</h4>
              <ul class="list_agree">
                <li>
                  회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를
                  요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도
                  거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의
                  변경 사항에 동의한 것으로 간주됩니다
                  <ul class="list_mini">
                    <li>
                      ① 이 약관의 서비스 화면에 게시하거나 공지사항 게시판 또는
                      기타의 방법으로 공지함으로써 효력이 발생됩니다.
                    </li>
                    <li>
                      ② 회사는 필요하다고 인정되는 경우 이 약관의 내용을 변경할
                      수 있으며, 변경된 약관은 서비스 화면에 공지하며, 공지후
                      7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속
                      사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
                    </li>
                    <li>
                      ③ 이용자가 변경된 약관에 동의하지 않는 경우 서비스 이용을
                      중단하고 본인의 회원등록을 취소할 수 있으며, 계속
                      사용하시는 경우에는 약관 변경에 동의한 것으로 간주되며
                      변경된 약관은 전항과 같은 방법으로 효력이 발생합니다.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>본 약관에 사용되는 용어 중 본
                  조에서 정하지 아니한 부분은 관계 법령 및 일반 관례에 따릅니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 4 조 (준용규정)</h4>
              <p class="desc_agree">
                이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법 및
                기타 관련법령의 규정에 따릅니다.
              </p>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제2장 서비스 이용계약</h2>

          <ol class="list_agreement">
            <li>
              <h4 className="tit_agree">제 5 조 (이용계약의 성립)</h4>
              <ul className="list_agree">
                <li>
                  이용계약은 이용자의 이용신청에 대한 회사의 승낙과 이용자의
                  약관 내용에 대한 동의로 성립됩니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">제 6 조 (이용신청)</h4>
              <ul className="list_agree">
                <li>
                  이용신청은 서비스의 회원정보 화면에서 이용자가 회사에서
                  요구하는 가입신청서 양식에 개인의 신상정보를 기록하여 신청할
                  수 있습니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">제 7 조 (이용신청의 승낙)</h4>
              <ul className="list_agree">
                <li>
                  ① 회원이 신청서의 모든 사항을 정확히 기재하여 이용신청을
                  하였을 경우에 특별한 사정이 없는 한 서비스 이용신청을
                  승낙합니다.
                </li>
                <li>
                  ② 다음 각 호에 해당하는 경우에는 이용 승낙을 하지 않을 수
                  있습니다.
                </li>
                <li>1. 본인의 실명으로 신청하지 않았을 때</li>
                <li>2. 타인의 명의를 사용하여 신청하였을 때</li>
                <li>3. 이용신청의 내용을 허위로 기재한 경우</li>
                <li>
                  4. 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청하였을
                  때
                </li>
                <li>5. 기타 회사가 정한 이용신청 요건에 미비 되었을 때 </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">제 8 조 (계약사항의 변경)</h4>
              <ul className="list_agree">
                <li>
                  회원은 이용신청시 기재한 사항이 변경되었을 경우에는 수정하여야
                  하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게
                  있습니다.
                </li>
              </ul>
            </li>
          </ol>

          <div class="bar_division"></div>
          <h2 class="tit_cate">제3장 계약당사자의 의무</h2>
          <ol class="list_agreement">
            <li>
              <h4 className="tit_agree">제 9 조 (회사의 의무)</h4>
              <ul className="list_agree">
                <li>
                  회사는 서비스 제공과 관련해서 알고 있는 회원의 신상 정보를
                  본인의 승낙 없이 제3자에게 누설하거나 배포하지 않습니다. 단,
                  전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는
                  경우, 범죄에 대한 수사상의 목적이 있거나 또는 기타
                  관계법령에서 정한 절차에 의한 요청이 있을 경우에는 그러하지
                  아니합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 10 조 (회원의 의무)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>"회원"은 “유료서비스” 이용 전에
                  반드시 "회사" 또는 “판매자”가 제공하는 “유료서비스”의 상세
                  내용과 거래의 조건을 정확하게 확인한 후 구매를 하여야 합니다.
                  거래의 내용과 조건을 확인하지 않고 구매하여 발생한 손실,
                  손해는 "회원"에게 귀속됩니다.
                </li>
                <li>
                  <span class="txt_num">②</span>"회원"은 본 약관 및 "회사"가
                  “서비스”와 관련하여 고지하는 내용을 준수하여야 하며, 본 약관
                  및 고지 내용을 위반하거나 이행하지 아니하여 발생하는 손실,
                  손해에 대한 책임을 부담합니다.
                </li>
                <li>
                  <span class="txt_num">③</span>"회원"은 "회사"가 정하거나
                  인정하는 방식에 따라 마일리지, 쿠폰을 사용하여야 합니다.
                </li>
                <li>
                  <span class="txt_num">④</span>"회원"은 “서비스”를 이용할 때
                  다음 각 호에 해당하는 행위를 해서는 안 됩니다.
                  <ul class="list_mini">
                    <li>1. 다른 회원의 ID를 부정하게 사용하는 행위</li>
                    <li>
                      2. 서비스에서 얻은 정보를 복제, 출판 또는 제3자에게
                      제공하는 행위
                    </li>
                    <li>
                      3. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는
                      행위
                    </li>
                    <li>
                      4. 공공질서 및 미풍양속에 위반되는 내용을 유포하는 행위
                    </li>
                    <li>5. 범죄와 결부된다고 객관적으로 판단되는 행위</li>
                    <li>
                      6. 회사 또는 직원, 관리자를 사칭, 혼동시키거나 이와
                      유사하다고 판단되는 행위
                    </li>
                    <li>7. 기타 관계법령에 위반되는 행위</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제4장 서비스 이용</h2>
          <ol class="list_agreement">
            <li>
              <h4 class="tit_agree">제 11 조 (회원의 의무)</h4>
              <ul class="list_agree">
                <li>
                  <span class="txt_num">①</span>회원은 필요에 따라 자신의 메일,
                  게시판, 등록자료 등 유지보수에 대한 관리책임을 갖습니다.
                </li>
                <li>
                  <span class="txt_num">②</span>회원은 회사에서 제공하는 자료를
                  임의로 삭제, 변경할 수 없습니다.
                </li>
                <li>
                  <span class="txt_num">③</span>③ 회원은 회사의 홈페이지에
                  공공질서(스팸글 작성, 테스트 포함) 및 미풍양속에 위반되는
                  내용물이나 제3자의 저작권 등 기타권리를 침해하는 내용물을
                  등록하는 행위를 하지 않아야 합니다. 만약 이와 같은 내용물을
                  게재하였을 때 우선 탈퇴시키며 이것으로 발생하는 결과에 대한
                  모든 책임은 회원에게 있습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">제 12 조 (게시물 관리 및 삭제)</h4>
              <ul class="list_agree">
                <li>
                  효율적인 서비스 운영을 위하여 회원의 메모리 공간, 메시지크기,
                  보관일수 등을 제한할 수 있으며 등록하는 내용이 다음 각 호에
                  해당하는 경우에는 사전 통지없이 삭제할 수 있습니다.
                  <ul class="list_mini">
                    <li>
                      1. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
                      손상시키는 내용인 경우
                    </li>
                    <li>2. 공공질서 및 미풍양속에 위반되는 내용인 경우</li>
                    <li>3. 범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
                    <li>
                      4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는
                      내용인 경우
                    </li>
                    <li>
                      5. 회원이 회사의 홈페이지와 게시판에 음란물을 게재하거나
                      음란 사이트를 링크하는 경우
                    </li>
                    <li>
                      6. 광고글 또는 광고글과 유사한 성격의 글이라고 판단되는
                      경우
                    </li>
                    <li>7. 기타 관계법령에 위반된다고 판단되는 경우</li>
                  </ul>
                </li>
                <li>
                  <span class="txt_num">②</span>제1항의 계약해제, 해지는
                  "회원"이 전화, 전자우편 등으로 "회사"에 그 의사를 표시한 때에
                  효력이 발생합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">
                제 13 조 (회사의 계약해제, 해지 및 이용제한)
              </h4>
              <ul class="list_agree">
                <li>
                  게시물의 저작권은 게시자 본인에게 있으며 회원은 서비스를
                  이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된
                  자료를 상업적으로 사용할 수 없습니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">제 14 조 (서비스 이용시간)</h4>
              <ul className="list_agree">
                <li>
                  서비스의 이용은 업무상 또는 기술상 특별한 지장이 없는 한
                  연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의
                  사유 발생시는 그러하지 않습니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">제 15 조 (서비스 이용 책임)</h4>
              <ul className="list_agree">
                <li>
                  서비스를 이용하여 해킹, 음란사이트 링크, 상용S/W 불법배포 등의
                  행위를 하여서는 아니되며, 이를 위반으로 인해 발생한 영업활동의
                  결과 및 손실, 관계기관에 의한 법적 조치 등에 관하여는 회사는
                  책임을 지지 않습니다.
                </li>
              </ul>
            </li>

            <li>
              <h4 class="tit_agree">제 16 조 (서비스 제공의 중지)</h4>
              <ul class="list_agree">
                <li>
                  다음 각 호에 해당하는 경우에는 서비스 제공을 중지할 수
                  있습니다.
                </li>
                <ul className="list_mini">
                  <li>1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                  <li>
                    2. 전기통신사업법에 규정된 기간통신사업자가 전기통신
                    서비스를 중지했을 경우
                  </li>
                  <li>3. 시스템 점검이 필요한 경우</li>
                  <li>4. 기타 불가항력적 사유가 있는 경우</li>
                </ul>
              </ul>
            </li>
            <li>
              <h4 className="tit_agree">제17조 (결제수단 등)</h4>
              <ul className="list_agree">
                <li>
                  <span className="txt_num">①</span>① 회원이 결제를 위하여
                  이용할 수 있는 지불수단은 다음 각 호와 같습니다. 단, 회사의
                  사정에 따라 특정 결제수단이 추가되거나 특정 결제수단의 제공이
                  중단 또는 종료될 수 있습니다.
                </li>
                <li>1. 신용카드: ㈜NHN KCP, ㈜다날</li>
                <li>
                  <span className="txt_num">②</span>"회원이 정기결제 멤버십을
                  구매하는 경우, 멤버십 구매 시 사용한 결제수단으로 결제시점
                  이후 일정시기에 자동으로 결제가 진행됩니다. 단, 회사는 자동
                  결제가 되는 멤버십의 금액이 변경되는 등 변경사항이 있는 경우
                  회원에게 미리 알리고 동의를 받은 이후 자동결제가 진행되게
                  합니다.
                </li>
                <li>
                  <span className="txt_num">③</span>결제수단 중 신용카드를
                  선택한 회원의 경우에는 다음 각호에 따릅니다.
                </li>
                <br />
                <li>
                  1. 회원이 신용카드 결제를 통해 물품 또는 용역을 구매할 수 있는
                  한도는 각 결제사가 정한 기준에 따릅니다.
                </li>
                <li>
                  2. 회원은 서비스의 이용요금을 회원의 해당 신용카드사의 요금
                  청구와 납부 시스템에 의하여 납부를 하며, 서비스의 이용요금을
                  정상적으로 납부하지 않은 경우 회사는 해당 회원의 서비스 이용을
                  제한할 수 있습니다.
                </li>
                <li>
                  <span className="txt_num">④</span>결제 시 사용되는
                  아이디(ID)와 비밀번호(PASSWORD)에 대한 관리 책임은 회원에게
                  있으며 회원 본인의 아이디(ID) 및 비밀번호(PASSWORD)의 관리
                  소홀/부정사용으로 인한 노출 또는 도용 시 회원은 즉각적으로
                  비밀번호를 변경해야 하며, 이에 따른 결제 피해에 대한 책임은
                  회원 본인에게 있습니다.
                </li>
              </ul>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제5장 청약철회, 계약해지 및 이용제한</h2>
          <ol class="list_agreement">
            <li>
              <h4 className="tit_agree">
                제18조 (청약철회, 계약해제 및 해지 등)
              </h4>
              <ul className="list_agree">
                <li>
                  <span className="txt_num">①</span>멤버십 유료회원은 해당
                  유료서비스 내지 멤버십을 전혀 사용하지 아니하였을 경우에
                  한하여 결제일로부터 7일 이내에 회사에 결제 취소(청약 철회)를
                  요청 할 수 있습니다. 단, 유료회원은 해당 유료서비스 내지
                  이용권의 내용이 표시•광고 내용과 다르거나 계약 내용과 다르게
                  이행된 경우에는 해당 콘텐츠를 공급받은 날로부터 3개월 혹은 그
                  사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회
                  등을 할 수 있습니다.
                </li>
                <li>1. 신용카드: ㈜NHN KCP, ㈜다날</li>
                <li>
                  <span className="txt_num">②</span>멤버십 유료회원이 제1항의
                  청약 철회가 가능한 유료서비스 또는 멤버십에 대하여 청약 철회
                  가능한 기간(결제일로부터 7일 이내)을 경과하여 청약 철회를
                  신청하거나, 전자상거래 등에서의 소비자 보호에 관한 법률,
                  콘텐츠산업진흥법, 콘텐츠이용자보호지침 등 기타 관계 법령에서
                  정한 청약 철회 제한 사유에 해당되고 회사가 이를 표시하는 등
                  적법한 조치를 취한 콘텐츠의 경우에는 청약 철회가 제한됩니다.
                </li>
                <li>
                  <span className="txt_num">③</span>회원은 다음 각 호의 사유가
                  있을 때 서비스에 대한 이용계약을 해지 또는 해제할 수 있습니다.
                </li>
                <br />
                <li>
                  1. 서비스를 구매한 날부터 7일 이후에 회원이 요청한 경우(단,
                  전자상거래 등에서의 소비자 보호에 관한 법률, 콘텐츠산업진흥법,
                  콘텐츠이용자보호지침 등 기타 관계 법령에서 정한 계약해지제한
                  사유에 해당되고 회사가 이를 표시하는 등 적법한 조치를 취한
                  콘텐츠의 경우에는 계약 해지가 제한될 수 있습니다).
                </li>
                <li>
                  2. 서비스의 하자를 회사가 보완, 수정할 수 없는 경우 음악 듣기
                  이용권을 공급받은 날로부터 1개월 이내 회원이 요청한 경우
                </li>
                <li>
                  3. 서비스를 이용하는 회원이 본 약관 변경에 동의하지 않아
                  회원탈퇴 하는 경우
                </li>
                <li>
                  <span className="txt_num">④</span>본조 제3항의 계약 해지 또는
                  해지 신청은 유선 전화문의를 통해 할 수 있으며, 회사에 그
                  의사표시가 도달한 때에 효력이 발생합니다. 회사는 회원의
                  의사표시를 수령한 후 지체 없이 이러한 사실을 회원에게
                  회신합니다.
                </li>
                <li>
                  <span className="txt_num">⑤</span>본 항 각호의 멤버십을 구매한
                  회원이 이용기간 중 고객의 의사에 따라 해당 멤버십을 중도
                  해지하는 때에는, 회사는 회원이 구매 지급한 대금에서 회원이
                  실제 사용한 기간 등 멤버십을 통하여 취득한 이익을 감안,
                  합리적인 범위 내에서 회사가 적용하는 소정의 기준율을 적용하여
                  차감하고 환불합니다.
                </li>
                <li>
                  1. 스타 멤버십 이용권: 제휴매장에서 주유시 리터(L)당 40원 추가
                  할인, 세차, 케미칼 용품 최대 40% 할인
                </li>
                <li>
                  2. 아차키 멤버십 이용권: 제휴매장에서 주유시 리터(L)당 40원
                  추가 할인, 세차, 케미칼 용품 최대 40% 할인, 아차키 서비스 제공
                </li>

                <li>
                  <span className="txt_num">⑥</span>본조에 따라 회사가 환불을
                  하는 때에는 회사는 회사가 부담하였거나 부담할 결제비용,
                  송금수수료 등을 차감하여 환불 할 수 있습니다.
                </li>

                <li>
                  <span className="txt_num">⑦</span>회원이 자동결제 이용권
                  이용을 해지하고자 하는 경우 이용권 해지 기능을 통해 해지
                  예약을 신청할 수 있고, 해지 예약 신청 시 이용중인 서비스의
                  종료일에 적용, 해지되며 추후 결제는 이루어지지 않습니다.
                </li>

                <li>
                  <span className="txt_num">⑧</span>자동결제 중인 서비스의 경우,
                  해당 회원이 서비스의 이용요금을 체납하는 경우 연체가 발생한 날
                  자동으로 상품 해지가 될 수 있으므로, 자동결제를 통한 혜택을
                  유지하고자 하는 회원은 이용요금의 체납 또는 결제수단의 연체가
                  발생하지 않도록 사전에 조치하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">⑨</span>회원이 프로모션 등을 통해
                  무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니한
                  서비스에 대해서는 회사는 환불 의무를 부담하지 않습니다.
                </li>

                <li>
                  <span className="txt_num">⑩</span>회원이 서비스 이용 계약을
                  해지하고자 할 때에는 앱접속 > 멤버십 > 멤버십 구매내역 메뉴를
                  통해 회사에 해지 신청을 해야 합니다. 이용계약 해지로 발생한
                  제반 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이
                  종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할
                  수 있습니다.
                </li>

                <li>
                  <span className="txt_num">⑪</span>회원이 계약을 해지할 경우
                  관련 법령 및 회사의 개인정보처리방침에 따라 회사가 회원정보를
                  보유하는 경우를 제외하고는 해지 즉시 회원의 모든 개인정보 및
                  데이터는 삭제되므로 해지 및 탈퇴 시 사전 확인이 필요합니다.
                  회사가 데이터 등의 삭제를 안내하였음에도 불구하고 회원이 개인
                  데이터의 보존 등 적절한 조치를 취하지 아니하는 경우에는 회사는
                  책임을 지지 않습니다.
                </li>
                <li>
                  <span className="txt_num">⑫</span>회사는 ‘정보통신망 이용촉진
                  및 정보보호 등에 관한 법률’ 및 동법 시행령에 따라 1년간
                  서비스를 이용하지 않은 회원의 개인정보를 보호하기 위해
                  사용의사를 묻는 고지를 하고, 회사가 정한 기한 내에 답변이 없는
                  경우 개인정보 파기 및 이용 계약을 해지합니다.
                </li>
                <li>
                  <span className="txt_num">⑬</span>회사는 당해 회원이 다음 각
                  호에 해당하는 경우, 당해 회원에 대하여 경고, 일시 이용정지,
                  영구 이용정지(향후 가입신청 승인 거절), 이용 계약 등 회원의
                  서비스 이용을 제한할 수 있습니다. 회원은 회사의 이용 제한에
                  대하여 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이
                  때 회원의 이의신청이 정당하다고 회사가 인정하는 경우, 회사는
                  즉시 회원이 서비스 이용을 재개할 수 있도록 조치를 취합니다.
                </li>
                <li>
                  1. 회원이 본 약관, 이용 지침, 이용 안내 등 회사가 정한 규정을
                  위반하는 경우
                </li>
                <li>
                  2. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확하거나
                  허위일 경우
                </li>
                <li>3. 회원이 본 약관 제10조를 위반하는 경우</li>
                <li>
                  4. 회원이 회사가 정한 콘텐츠의 이용 범위를 위반하여
                  이용함으로써 회사 또는 콘텐츠 저작권자의 권리를 침해한 경우
                </li>
              </ul>
            </li>

            <li>
              <h4 className="tit_agree">
                제 19 조 (청약철회와 계약해제 및 해지의 효과)
              </h4>
              <ol className="list_agreement">
                <li>
                  <span className="txt_num">①</span>회사는 회원에게 계약해제 및
                  해지의 의사표시에 대하여 회사의 특별한 사정이 없는 한 회원의
                  의사 표시를 한 날로부터 3영업일 이내에 대금의 결제와 동일한
                  방법으로 이를 환급하여야 하며, 동일한 방법으로 환불이 불가능할
                  때에는 이를 사전에 고지하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">②</span>회사는 위 대금을 환급함에
                  있어서 해당 결제수단을 제공한 사업자에게 재화 등의 대금의
                  청구를 정지 또는 취소하도록 요청합니다. 단, 제1항 단서의
                  경우에는 그러하지 아니할 수 있습니다.
                </li>
                <li>
                  <span className="txt_num">③ </span>계약 해제 및 해지 요청에
                  따라 각 결제수단 별 결제취소 절차에 따라 환불합니다. 단,
                  휴대폰의 경우에는 결제한 해당월 내에만 결제취소가 가능하고 각
                  결제수단별로 취소 가능 기간이 지난 경우에는 환불
                  수수료(송금수수료 및 결제대행수수료 등)를 공제한 잔액을
                  현금으로 반환합니다.
                </li>
                <li>
                  <span className="txt_num">④</span>회사, 서비스 등의 대금을
                  지급 받은 자 또는 회원과 서비스 이용계약을 체결한 자가
                  동일인이 아닌 경우에 각자는 청약철회 또는 계약해제 및 해지로
                  인한 대금환급과 관련한 의무의 이행에 있어서 연대하여 책임을
                  집니다.
                </li>
              </ol>
            </li>

            <li>
              <h4 className="tit_agree">제 20 조 (계약해지 및 이용제한)</h4>
              <li>
                <span className="txt_num">①</span>회원이 이용계약을 해지하고자
                하는 때에는 회원 본인이 인터넷을 통하여 해지신청을 하여야 하며,
                회사에서는 본인 여부를 확인 후 조치합니다.
              </li>
              <li>
                <span className="txt_num">②</span>회사는 회원이 다음 각 호에
                해당하는 행위를 하였을 경우 해지조치 30일전까지 그 뜻을
                이용고객에게 통지하여 의견진술할 기회를 주어야 합니다.
              </li>
              <li>1. 타인의 이용자ID 및 패스워드를 도용한 경우</li>
              <li>2. 서비스 운영을 고의로 방해한 경우</li>
              <li>3. 허위로 가입 신청을 한 경우</li>
              <li>4. 같은 사용자가 다른 ID로 이중 등록을 한 경우</li>
              <li>5. 공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우</li>
              <li>
                6. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
              </li>
              <li>
                7. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를
                전송하거나 광고성 정보를 전송하는 경우
              </li>
              <li>
                8. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는
                컴퓨터바이러스 프로그램 등을 유포하는 경우
              </li>
              <li>
                9. 회사 또는 다른 회원이나 제3자의 지적재산권을 침해하는 경우
              </li>
              <li>
                10. 타인의 개인정보, 이용자ID 및 패스워드를 부정하게 사용하는
                경우
              </li>
              <li>
                11. 회원이 자신의 홈페이지나 게시판 등에 음란물을 게재하거나
                음란 사이트를 링크하는 경우
              </li>
              <li>12. 기타 관련법령에 위반된다고 판단되는 경우</li>
            </li>
          </ol>
          <div class="bar_division"></div>
          <h2 class="tit_cate">제6장 기타</h2>
          <ol className="list_agreement">
            <li>
              <h4 className="tit_agree">제 21 조 (양도금지) </h4>
              <ul className="list_agree">
                <li>
                  회원은 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게
                  양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 className="tit_agree">제 22 조 (손해배상)</h4>
              <ul className="list_agree">
                <li>
                  회사는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한
                  손해가 발생하더라도 동 손해가 회사의 고의 또는 중대한 과실로
                  인한 손해를 제외하고 이에 대하여 책임을 부담하지 아니합니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 className="tit_agree">제 23 조 (면책 조항)</h4>
              <ul className="list_agree">
                <li>
                  <span className="txt_num">①</span>회사는 천재지변, 전쟁 또는
                  기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
                  경우에는 서비스 제공에 관한 책임이 면제됩니다.
                </li>

                <li>
                  <span className="txt_num">②</span>회사는 서비스용 설비의 보수,
                  교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한
                  책임이 면제됩니다.
                </li>

                <li>
                  <span className="txt_num">③</span>회사는 회원의 귀책사유로
                  인한 서비스이용의 장애에 대하여 책임을 지지 않습니다.
                </li>

                <li>
                  <span className="txt_num">④</span>회사는 회원이 서비스를
                  이용하여 기대하는 이익이나 서비스를 통하여 얻는 자료로 인한
                  손해에 관하여 책임을 지지 않습니다.
                </li>

                <li>
                  <span className="txt_num">⑤</span>회사는 회원이 서비스에
                  게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
                  책임을 지지 않습니다.
                </li>
              </ul>
            </li>
            <li>
              <h4 className="tit_agree">제 24 조 (관할법원)</h4>
              <ul className="list_agree">
                <li>
                  서비스 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 회사의
                  소재지를 관할하는 법원을 전속 관할법원으로 합니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </main>
    </div>
  );
}

export default Policy;

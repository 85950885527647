import { Card } from "@material-ui/core";
import React from "react";
import { Tabs, Tab, DropdownButton, Dropdown, Form, Col, Button, Modal, Row } from "react-bootstrap";
import AdminTable from "../../components/AdminTable";
import { db } from "../../firebase";
import "./Setting.css";

function Setting() {
  const [storeType, setStoreType] = React.useState({ value: "", name: "" });
  const [storeList, setstoreList] = React.useState();
  const [showModal, setshowModal] = React.useState({ show: false, type: "notice" });

  const [storeDetail, setstoreDetail] = React.useState();

  async function loadStore(type) {
    setstoreDetail(undefined);
    const snapshot = await db.collection(type).get();
    let data = snapshot.docs.map(doc => doc.data());
    console.log(data);
    setstoreList(data);
  }

  const onFormSubmit = e => {
    e.preventDefault();
    console.log(e.target.name.value);
  };

  function writePost() {
    try {
      let title = document.getElementById("new_title");
      let content = document.getElementById("new_content");

      if (title.value === "" || content.value === "") {
        alert("제목과 내용을 적어주세요.");
      } else {
        let postData = {
          title: title.value,
          content: content.value,
          created: new Date().getTime(),
          type: showModal.type,
          comments: [],
        };

        if (showModal.type === "event") {
          let status = document.getElementById("new_status");
          postData["status"] = status.options[status.selectedIndex].value;
        }

        console.log(postData);
        try {
          db.collection("adminBoard")
            .add(postData)
            .then(async docRef => {
              await db.collection("adminBoard").doc(docRef.id).update({ id: docRef.id });
              alert("등록하였습니다.");
              window.location.reload(true);
            })
            .catch(error => {
              alert(error);
            });
        } catch (error) {
          alert(error);
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div className="Dashboard" style={{ height: "90vh" }}>
      <Modal show={showModal.show} onHide={() => setshowModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">게시물 작성</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="userForm">
            <Form.Group as={Row}>
              <Form.Label column sm="1.5">
                제목
              </Form.Label>
              <Col>
                <Form.Control as="input" id="new_title" />
              </Col>
            </Form.Group>

            {showModal.type === "event" && (
              <Form.Group as={Row}>
                <Form.Label column sm="1.5">
                  상태
                </Form.Label>
                <Col>
                  <Form.Control as="select" defaultValue="ON" id="new_status">
                    <option value="ON">진행중</option>
                    <option value="OFF">종료</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label>내용</Form.Label>
              <Form.Control as="textarea" rows={10} id="new_content" wrap="hard" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              writePost();
            }}>
            등록하기
          </Button>
          <Button variant="dark" onClick={() => setshowModal(false)}>
            취소
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="qna_board">
        <div className="MTableToolbar-title-9">
          {/* <h6 className="MuiTypography-root MuiTypography-h6" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            앱 설정
          </h6> */}

          <Tabs defaultActiveKey="store" id="uncontrolled-tab-example">
            <Tab eventKey="store" title="매장관리">
              <div style={{ margin: "1rem", display: "flex" }}>
                <DropdownButton id="dropdown-basic-button" title={`${storeType.name === "" ? "서비스 종류" : storeType.name}`} variant="outline-danger">
                  <Dropdown.Item
                    onClick={() => {
                      setStoreType({ value: "station", name: "주유소" });
                      loadStore("station");
                    }}>
                    주유소
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStoreType({ value: "wash", name: "세차장" });
                      loadStore("wash");
                    }}>
                    세차장
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStoreType({ value: "lamp", name: "석유" });
                      loadStore("lamp");
                    }}>
                    석유
                  </Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title={`${storeDetail === undefined ? "매장 선택" : storeDetail.name}`} variant="outline-danger">
                  {storeList !== undefined ? (
                    storeList.map((store, key) => (
                      <Dropdown.Item
                        key={key}
                        onClick={() => {
                          setstoreDetail(store);
                        }}>
                        {store.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item href="#/action-3">서비스 종류를 먼저 선택해주세요!</Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-3">신규매장 등록</Dropdown.Item>
                </DropdownButton>

                {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </DropdownButton> */}
              </div>
              {storeDetail !== undefined && (
                <Card className="detailCard">
                  <Form className="userForm" onSubmit={onFormSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md="2" controlId="formName">
                        <Form.Label>매장 명</Form.Label>
                        <Form.Control name="name" plaintext value={storeDetail.name} />
                      </Form.Group>

                      <Form.Group as={Col} md="3" controlId="formAddr">
                        <Form.Label>주 소</Form.Label>
                        <Form.Control plaintext defaultValue={storeDetail.address} />
                      </Form.Group>

                      <Form.Group as={Col} md="3" controlId="formTime">
                        <Form.Label>영업시간</Form.Label>
                        <Form.Control plaintext defaultValue={storeDetail.time} />
                      </Form.Group>

                      <Form.Group as={Col} md="2" controlId="formFill">
                        <Form.Label>필터</Form.Label>
                        <Form.Control as="select" defaultValue={storeDetail.filter[0]}>
                          {storeDetail.filter.map((item, key) => (
                            <option value={item} key={key}>
                              {"필터"}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    {storeType.value === "station" && (
                      <Form.Row>
                        <Form.Group as={Col} md="2" controlId="formName">
                          <Form.Label>휘발유</Form.Label>
                          <Form.Control name="name" plaintext defaultValue={storeDetail.gasoline} />
                        </Form.Group>

                        <Form.Group as={Col} md="2" controlId="formName">
                          <Form.Label>경유</Form.Label>
                          <Form.Control name="name" plaintext defaultValue={storeDetail.diesel} />
                        </Form.Group>
                      </Form.Row>
                    )}
                    <Form.Row>
                      <Button className="detailBtn" onClick={() => {}} as={Col} variant="danger">
                        새로고침
                      </Button>
                      <Button className="detailBtn" as={Col} variant="danger">
                        전체 저장
                      </Button>
                    </Form.Row>
                  </Form>
                </Card>
              )}
            </Tab>
            <Tab eventKey="setting" title="앱 설정" variant="outline-danger">
              앱 팝업
            </Tab>

            <Tab eventKey="notiboard" title="공지사항" variant="outline-danger">
              <AdminTable type={"notice"} setshowModal={setshowModal} />
            </Tab>

            <Tab eventKey="eventboard" title="이벤트" variant="outline-danger">
              <AdminTable type={"event"} setshowModal={setshowModal} />
            </Tab>

            {/* <Tab eventKey="contact" title="Contact">
              <Sonnet />
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Setting;

import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import NotFound from "./pages/NotFound";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Policy from "./pages/Policy";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/adm" component={Admin} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/policy" component={Policy} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import MaterialTable from "material-table";
import React from "react";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import StarRounded from "@material-ui/icons/StarRounded";
import VpnKey from "@material-ui/icons/VpnKey";
import Refresh from "@material-ui/icons/Refresh";

import { setDate, getTimeStamp, db, FieldValue } from "../firebase";
import { Button, Col, Form, Row, Card, Badge } from "react-bootstrap";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import "./UserTable.css";

import { connect } from "react-redux";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  StarRounded: forwardRef((props, ref) => <StarRounded {...props} ref={ref} />),
  VpnKey: forwardRef((props, ref) => <VpnKey {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CheckCircleOutline: forwardRef((props, ref) => <CheckCircleOutline {...props} ref={ref} />),
  HighlightOff: forwardRef((props, ref) => <HighlightOff {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
};

function QnaTable({ user }) {
  // const [modalData, setmodalData] = React.useState({});
  const [data, setdata] = React.useState([]);

  const GetPosts = async () => {
    const snapshot = await db.collection("userBoard").orderBy("created", "desc").get();
    console.log(snapshot.size);
    const posts = snapshot.docs.map(doc => doc.data());
    console.log(posts);
    setdata(posts);
  };

  React.useEffect(() => {
    // console.log("effect!");
    GetPosts();
  }, []);

  // const onFormSubmit = e => {
  //   e.preventDefault();
  //   console.log(e.target.name.value);
  // };

  function addComment(id) {
    let comment = document.getElementById("new_comment");

    if (comment.value === "") {
      alert("내용을 입력해 주세요");
    } else {
      let postData = {
        name: user.name,
        comment: comment.value,
        created: new Date().getTime(),
      };

      console.log(postData);

      db.collection("userBoard")
        .doc(id)
        .update({ comments: FieldValue.arrayUnion(postData) })
        .then(() => {
          alert("등록되었습니다.");
          GetPosts();
        });
    }
  }
  return (
    <MaterialTable
      icons={tableIcons}
      options={{ detailPanelType: "multiple", exportButton: true, exportAllData: true, pageSize: 10, pageSizeOptions: [10, 15, 30], actionsColumnIndex: -1, sorting: true }}
      columns={[
        // { title: "UID", field: "uid" },
        { title: "이름", field: "name" },
        { title: "작성일", field: "created", type: "date", render: rowData => setDate(rowData.created) },
        { title: "내용", field: "content", render: rowData => rowData.content.substring(0, 20) },
      ]}
      data={data}
      actions={[
        rowData => ({
          icon: Delete,
          tooltip: "문의 삭제",
          onClick: (event, rowData) => {
            if (window.confirm(rowData.name + "님의 문의를 삭제할까요?")) {
              db.collection("userBoard")
                .doc(rowData.id)
                .delete()
                .then(() => GetPosts());
            }
          },
        }),
        {
          icon: Refresh,
          tooltip: "새로고침",
          isFreeAction: true,
          onClick: event => GetPosts(),
        },
      ]}
      title="문의사항"
      detailPanel={rowData => {
        return (
          <Card className="detailCard">
            <Form className="userForm">
              <Form.Group as={Row}>
                <Form.Label column sm="1">
                  이름
                </Form.Label>
                <Col sm="2">
                  <Form.Control plaintext readOnly defaultValue={rowData.name} />
                </Col>
                <Form.Label column sm="2">
                  작성일시
                </Form.Label>
                <Col sm="3">
                  <Form.Control plaintext readOnly defaultValue={getTimeStamp(rowData.created)} />
                </Col>
              </Form.Group>

              <Form.Group>
                <Form.Label>UID</Form.Label>
                <Form.Control plaintext defaultValue={rowData.uid} readOnly />
              </Form.Group>

              <Form.Group>
                <Form.Label>내용</Form.Label>
                <Form.Control as="textarea" rows={4} readOnly defaultValue={rowData.content} />
              </Form.Group>

              <Form.Group>
                <Form.Label>답변[{rowData.comments.length}]</Form.Label>
                {rowData.comments.length !== 0 &&
                  rowData.comments.map((comment, key) => (
                    <Card key={key}>
                      <Card.Body>
                        <Card.Text style={{ whiteSpace: "pre-line" }}>{comment.comment}</Card.Text>
                        {/* <Card.Text>{comment.comment}</Card.Text> */}
                        <Card.Text>
                          <small className="text-muted">
                            {comment.name} | {getTimeStamp(comment.created)}
                          </small>
                          <Badge
                            onClick={() => {
                              if (window.confirm("삭제할까요?")) {
                                db.collection("userBoard")
                                  .doc(rowData.id)
                                  .update({
                                    comments: FieldValue.arrayRemove(comment),
                                  })
                                  .then(() => {
                                    GetPosts();
                                  });
                              }
                            }}
                            variant="danger">
                            삭제
                          </Badge>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
              </Form.Group>

              <Form.Group>
                <Form.Label>답변 작성</Form.Label>
                <Form.Control id="new_comment" as="textarea" rows={4} defaultValue="" wrap="hard" />
              </Form.Group>
              <div style={{ textAlign: "end" }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    addComment(rowData.id);
                  }}>
                  등록하기
                </Button>
              </div>
            </Form>
          </Card>
          // <pre>
          //   <code>{JSON.stringify(rowData)}</code>
          // </pre>
        );
      }}
      onRowClick={(event, rowData, togglePanel) => {
        // setmodalData(rowData);
        togglePanel();
      }}
    />
  );
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.auth,
  };
};

export default connect(mapStateToProps)(QnaTable);

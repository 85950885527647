import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyC5CC-8BfV9hYkcmBFGf8UySYiAn449GBg",
  authDomain: "starcoex-jeju-e596d.firebaseapp.com",
  databaseURL: "https://starcoex-jeju-e596d.firebaseio.com",
  projectId: "starcoex-jeju-e596d",
  storageBucket: "starcoex-jeju-e596d.appspot.com",
  messagingSenderId: "362871688095",
  appId: "1:362871688095:web:12ca0176d7ad9081fccbaa",
  measurementId: "G-D4ZMTX95XC",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const FieldValue = firebase.firestore.FieldValue;

export async function loginUser(username, password) {
  const email = `${username}@starcoex.com`;
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return [true, await firebase.auth().signInWithEmailAndPassword(email, password)];
  } catch (error) {
    return [false, error];
  }
}

export function logoutUser() {
  return firebase.auth().signOut();
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
      unsubscribe();
    });
  });
}

export function formatDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function setDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getTimeStamp(date) {
  var d = new Date(date);
  var s = leadingZeros(d.getFullYear(), 4) + "-" + leadingZeros(d.getMonth() + 1, 2) + "-" + leadingZeros(d.getDate(), 2) + " " + leadingZeros(d.getHours(), 2) + ":" + leadingZeros(d.getMinutes(), 2);
  // ":" +
  // leadingZeros(d.getSeconds(), 2);

  return s;
}

function leadingZeros(n, digits) {
  var zero = "";
  n = n.toString();

  if (n.length < digits) {
    for (let i = 0; i < digits - n.length; i++) zero += "0";
  }
  return zero + n;
}

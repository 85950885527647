import React from "react";

import { connect } from "react-redux";
import { Route } from "react-router-dom";

import Header from "../components/Header";
import Login from "../components/Login";
import Sidebar from "../components/Sidebar";
import { db, getCurrentUser, logoutUser } from "../firebase";
import { userLogin } from "../redux";
import "./Admin.css";
import Dashboard from "./Dashboard/";
import Membership from "./Dashboard/Membership";
import Payments from "./Dashboard/Payments";
import Setting from "./Dashboard/Setting";
import Users from "./Dashboard/Users";
import Qna from "./Dashboard/Qna";

function Admin({ user, userLogin, match, location, history }) {
  const [busy, setbusy] = React.useState(true);
  React.useEffect(() => {
    console.log("effect!");
    getCurrentUser().then(user => {
      if (user) {
        db.collection("admin")
          .doc(user.uid)
          .get()
          .then(snap => {
            if (snap.data().web) {
              console.log(snap.data());
              userLogin({ uid: snap.data().uid, name: snap.data().nick, permission: snap.data().permission });
              setbusy(false);
            } else {
              logoutUser();
              setbusy(false);
            }
          });
      } else {
        logoutUser();
        setbusy(false);
      }

      // if (user) {
      //   userLogin({ uid: "test", name: "관리자", permission: [] });
      //   setbusy(false);
      // } else {
      //   setbusy(false);
      // }
    });
  }, [userLogin]);

  // const startDate = (e) => {
  //   console.log(e.target.value);
  // };
  // const endDate = (e) => {
  //   console.log(e.target.value);
  // };
  return (
    <>
      {busy ? (
        "loading..."
      ) : user.uid !== null ? (
        <div style={{ width: "100%", height: "100%" }}>
          <Sidebar />
          <div className="main" id="main">
            <Header />
            {/* <Form.Group controlId="dob">
              <Form.Label>Select Date</Form.Label>
              <Form.Control type="date" name="startDate" value={formatDate()} onChange={startDate} />
              <Form.Control type="date" name="endDate" value={formatDate()} onChange={endDate} />
            </Form.Group> */}
            {/* <button onClick={getUserCount}>버튼</button> */}
            <div className="contents">
              <Route exact path={match.path} component={Dashboard} />
              <Route path={`${match.path}/users`} component={Users} />
              <Route path={`${match.path}/payments`} component={Payments} />
              <Route path={`${match.path}/membership`} component={Membership} />
              <Route path={`${match.path}/qna`} component={Qna} />
              <Route path={`${match.path}/setting`} component={Setting} />
            </div>

            {/* <pre>{JSON.stringify(match, null, 2)}</pre>
            <pre>{JSON.stringify(location, null, 2)}</pre>
            <pre>{JSON.stringify(history, null, 2)}</pre> */}
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.auth,
  };
};

const mapDispatchToProps = {
  userLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

import React from "react";

import "./Terms.css";

function Terms(props) {
  return (
    <div id="termsWrap" className="terms_wrap">
      <header id="header">
        <div className="head">
          <h1 className="billing"> 스타코엑스 개인정보처리방침</h1>
        </div>
      </header>

      <main id="kakaoContent">
        <div class="cont_agreement">
          <ol class="list_agreement">
            <li>
              <p class="desc_agree">
                “주식회사 스타코엑스이하 “회사” 또는 “스타코엑스” 이라 함)은
                개인정보보호법, 정보통신망 이용촉진 및 정보보호에 관한 법률,
                통신비밀보호법 등 정보통신서비스제공자가 준수하여야 할 관련
                법령상의 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을
                정하여 이용자의 권익 보호에 최선을 다하고 있습니다. 본 개인정보
                처리방침은 회사가 제공하는 서비스 이용에 적용되고 다음과 같은
                내용을 담고 있습니다.
              </p>
            </li>
            <ul>
              <h4 class="tit_agree">개인정보 수집 및 이용 현황</h4>
              <p class="list_mini">
                스타코엑스는 원활한 서비스 제공을 위해 다음과 같은 이용자의
                개인정보를 처리하고 있습니다.
              </p>
              <li>수집 및 이용 현황</li>
            </ul>
            <table class="table">
              <thead>
                <tr>
                  <th>서비스</th>
                  <th>수집 및 이용목적</th>
                  <th>구분</th>
                  <th>수집 및 이용 항목</th>
                  <th>보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>회원 가입</td>
                  <td>
                    서비스 이용을 위한 이용자 식별
                    <br />
                    이용자 개별적 통지 및 고지
                  </td>
                  <td>필수</td>
                  <td>휴대폰번호, 이름</td>
                  <td>
                    회원탈퇴시 까지
                    <br />
                    <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인
                    경우에는 해당 수사, 조사 종료 시 까지 보관 하며 내부규정
                    혹은 관련법령에 따라 일정기간 보관됨.
                  </td>
                </tr>
                <tr></tr>

                <tr>
                  <td>본인인증</td>
                  <td>앱 내 본인인증 서비스 제공</td>
                  <td>필수</td>
                  <td>
                    이름, CI, DI, 휴대폰번호, 생년월일, 성별, 통신사, 내/외국인
                    정보
                  </td>
                  <td>
                    회원탈퇴시 혹은 동의 철회시 까지
                    <br />
                    <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인
                    경우에는 해당 수사, 조사 종료 시 까지 보관 하며 내부규정
                    혹은 관련법령에 따라 일정기간 보관됨.
                  </td>
                </tr>
                <tr>
                  <td>마케팅</td>
                  <td>
                    서비스에서 제공하는 혜택, 이벤트, 상품정보, 신규서비스 안내
                  </td>
                  <td>선택</td>
                  <td>앱 내 알림</td>
                  <td>회원탈퇴시 혹은 동의 철회시 까지</td>
                </tr>
                <tr>
                  <td rowSpan="3">고객문의</td>
                  <td rowSpan="3">
                    본인확인 및 고객요청사항 처리 및 처리결과에 대한 회신
                  </td>
                  <td>필수</td>
                  <td>휴대폰번호, 상담내역</td>
                  <td>3년</td>
                </tr>
              </tbody>
            </table>
            <li>
              <h4 class="tit_agree">
                스타코엑스에서 수집 및 이용되는 개인정보는 다음과 같은 경로로
                수집 되고 있습니다.
              </h4>
              <ul class="list_agree">
                <li>
                  개인정보 수집 방법
                  <ul class="list_mini">
                    <li>
                      회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에
                      대해 동의하고 직접 정보를 입력하는 경우
                    </li>
                    <li>
                      고객센터를 통한 상담과정에서 앱, 메일, 전화, 팩스 등을
                      통해 개인정보를 수집하는 경우
                    </li>
                    <li>서비스 이용과정에서 이용자로부터 수집하는 경우</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h4 class="tit_agree">만 14세 미만 아동의 개인정보 처리</h4>
              <p class="desc_agree">
                스타코엑스는 법정대리인의 동의가 필요한 만14세 미만 아동에 대한
                정보를 수집 및 이용하지 않습니다.
              </p>
            </li>
            <li>
              <h4 className="tit_agree">개인정보의 국외 이전에 관한 사항</h4>
              <p className="desc_agree">
                스타코엑스는 데이터 분석과 데이터 유실에 대비한 분산 저장을 위해
                사용자의 개인정보를 해외 클라우드 서비스에 위탁하고 있습니다.
              </p>

              <table class="table">
                <thead>
                  <tr>
                    <th>회사명</th>
                    <th>이전목적</th>
                    <th>연락처</th>
                    <th>개인정보 이전국가</th>
                    <th>이전되는 항목</th>
                    <th>이전 일시 및 방법</th>
                    <th>보유 및 이 용 기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Google Cloud Platform</td>
                    <td>Google Cloud Storage에 데이터 저장</td>
                    <td>080-822-1422</td>
                    <td>미국</td>
                    <td>수집하는 모든 개인정보</td>
                    <td>
                      데이터 수집 후 수분 이내 Google 클라우드 컴퓨팅 환경에
                      개인정보 보관
                    </td>
                    <td>회원탈퇴 또는 위탁계약 종료시</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h4 className="tit_agree">개인정보 파기 절차 및 방법</h4>
              <p className="desc_agree">
                이용자의 개인정보는 수집 및 이용목적이 달성되면 지체없이
                파기합니다.(여기서 ‘이용목적이 달성된 때’란 철회요청, 서비스계약
                만료, 탈퇴 시를 의미.) 다만, 회사 내부 방침 또는 관계 법령에서
                정한 보관기간이 있을 경우 일정 기간동안 보관 후 파기 됩니다.
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기하고,
                전자적 파일 형태로 저장된 기록은 재생할 수 없는 기술적 방법을
                사용하여 삭제합니다.
              </p>
            </li>
            <li>
              <h4 className="tit_agree">개인정보 보호책임자에 관한 사항</h4>
              <p className="desc_agree">
                사용자가 서비스를 이용하면서 발생하는 모든 개인정보보호 관련
                문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및
                담당부서로 연락해 주시기 바랍니다. 스타코엑스는 사용자 목소리에
                귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을
                다하겠습니다
                <br />- 이름 : 고정훈
                <br />- 직위 : 개인정보보호책임자
                <br />- 연락처 : crop.starcoex@gmail.com
              </p>
            </li>
            <li>
              <h4 className="tit_agree">정보주체의 권익침해에 대한 구제방법</h4>
              <p className="desc_agree">
                이용자는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을
                문의하실 수 있습니다
                <br />- 개인정보 침해신고센터 (한국인터넷진흥원 운영)
                <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청
                <br />- 홈페이지 : privacy.kisa.or.kr
                <br />- 전화 : (국번없이) 118
                <br />- 주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원
                <br />
                <br />- 개인정보 분쟁조정위원회
                <br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적
                해결)
                <br />- 홈페이지 : www.kopico.go.kr
                <br />- 전화 : 1833-6972
                <br />- 주소 : 서울특별시 종로구 세종대로 209 정부서울청사 12층
                <br />- 대검찰청 사이버수사과: (국번없이) 1301,
                privacy@spo.go.kr (www.spo.go.kr)
                <br />- 경찰청 사이버수사국: (국번없이) 182 (ecrm.cyber.go.kr)
                스타코엑스은 정보주체의 개인정보자기결정권을 보장하고,
                개인정보침해로 인한 상담 및 피해구제를 위해 노력하고 있으며,
                신고나 상담이 필요한 경우 개인정보관련 고객상담 접수 부서 (
                정보주체와 법정대리인의 권리 의무 및 행사 방법 참고) 로 연락 해
                주시기 바랍니다.
                <br /> 『개인정보보호법』 제25조(개인정보의 열람),
                제26조(개인정보의 정정 삭제), 제37조(개인정보의 처리정지 등)의
                규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는
                부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이
                정하는 바에따라 행정심판을 청구할 수 있습니다.
              </p>
            </li>

            <li>
              <h4 className="tit_agree">
                개인정보처리방침의 시행 및 변경에 관한 사항
              </h4>
              <p className="desc_agree">
                이 개인정보 처리방침은 2023년 3월 20일부터 시행되며 스타코엑스는
                법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로
                개인정보처리방침이 변경되는 경우 최소 7일 전부터 공지사항을 통해
                변경 사항을 고지 해드리도록 하겠습니다.
              </p>
            </li>
          </ol>
        </div>
      </main>
    </div>
  );
}

export default Terms;

import React from "react";
import { Fade } from "react-awesome-reveal";

import "./Intro3.css";

function Intro() {
  return (
    <div className="item" id="third">
      <section className="intro">
        <div className="centerBox">
          <div className="rightArea">
            {/* <div className="infoOrder text"> */}
            {/* <span className="orderBg"></span> */}
            {/* <span className="orderNumber">01</span> */}
            {/* </div> */}
            <Fade cascade direction="up">
              <h3 className="infoTitle text">
                <b style={{ color: "#fabe00", fontWeight: "bold" }}>
                  디지털키멤버십
                </b>
                서비스
                {/* <br />
                운전을 <b style={{ color: "#fabe00" }}>편리</b>하게 */}
              </h3>

              <p className="infoText text">
                스마트폰으로 도어 잠금헤제와 차량공유까지!
                <br />
                디지털키멤버십에 가입해 디지털키를 무료로 설치하세요!
              </p>
            </Fade>
          </div>
          <div className="slideArea3 text"></div>
        </div>
      </section>
    </div>
  );
}

export default Intro;

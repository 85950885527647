import React, { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { db, loginUser, logoutUser } from "../firebase";
import { userLogin } from "../redux";

const Login = ({ userLogin }) => {
  const [busy, setbusy] = useState(false);
  const [alert, setalert] = useState([false, ""]);
  const [id, setid] = useState("");
  const [pw, setpw] = useState("");

  const handleSubmit = async () => {
    setbusy(true);
    const login = await loginUser(id, pw);
    console.log("login:", login);

    if (login[0]) {
      const userData = await db.collection("admin").doc(login[1].user.uid).get();
      if (userData.data().web) {
        userLogin({ uid: userData.data().uid, name: userData.data().nick, permission: userData.data().permission });
        // debugger;
      } else {
        logoutUser();
        setalert([true, "접근 권한이 없는 유저 입니다."]);
      }
    } else {
      switch (login[1].code) {
        case "auth/invalid-email":
          setalert([true, "올바른 형식의 이메일이 아닙니다."]);
          break;

        default:
          setalert([true, "사용자명 또는 비밀번호가 일치하지 않습니다."]);
          break;
      }
    }

    setbusy(false);
  };

  const idChange = (e) => {
    setid(e.target.value);
  };
  const pwChange = (e) => {
    setpw(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <div>
            <h3>로그인</h3>

            <div className="form-group">
              <label>ID</label>
              <input type="text" className="form-control" placeholder="사용자 계정" id="id" onChange={idChange} />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input type="password" className="form-control" placeholder="비밀번호" id="pw" onChange={pwChange} />
            </div>

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" disabled />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>
            {alert[0] && (
              <Alert style={{ paddingRight: 0 }} variant="danger" onClose={() => setalert([false, ""])} dismissible>
                <Alert.Heading>오류</Alert.Heading>
                <p>{alert[1]}</p>
              </Alert>
            )}
            <button type="submit" className="btn btn-danger btn-block" onClick={handleSubmit} disabled={busy}>
              {busy ? <Spinner animation="border" size="sm" /> : "로그인"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user: user.auth,
  };
};

const mapDispatchToProps = { userLogin };

export default connect(mapStateToProps, mapDispatchToProps)(Login);

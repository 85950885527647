import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../firebase";
import { userLogout } from "../redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
const Header = ({ user, userLogout }) => {
  const logout = async (e) => {
    await logoutUser();
    userLogout();
  };
  return (
    <Navbar style={{ justifyContent: "space-between" }}>
      <Navbar.Brand href="/">
        <img alt="logo" style={{ fontWeight: "bold" }} src="https://starcoex.com/logo.ico" width="32px" className="d-inline-block align-center" />{" "}
        관리자페이지
      </Navbar.Brand>

      <Nav className="mr-sm-2">
        {/* <Nav.Link href="itms-services://?action=download-manifest&url=https://starcoex.com/apps/manifest.plist">관리자앱 다운로드</Nav.Link> */}
        <Navbar.Text style={{ color: "black", paddingRight: "0.5rem" }}>{user.name}님</Navbar.Text>
        <Nav.Link onClick={logout}>
          <ExitToAppIcon /> 로그아웃
        </Nav.Link>

        <NavDropdown title="Dropdown" id="nav-dropdown" style={{ display: "none" }}>
          <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
          <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
          <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user: user.auth,
  };
};

const mapDispatchToProps = { userLogout };

export default connect(mapStateToProps, mapDispatchToProps)(Header);

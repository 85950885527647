import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import EventIcon from "@material-ui/icons/Event";
import SettingsIcon from "@material-ui/icons/Settings";

export const SidebarData = [
  {
    title: "대시보드",
    icon: <HomeIcon />,
    link: "/adm",
  },
  {
    title: "회원관리",
    icon: <PersonIcon />,
    link: "/adm/users",
  },
  {
    title: "결제내역",
    icon: <AccountBalanceWalletIcon />,
    link: "/adm/payments",
  },
  {
    title: "멤버십",
    icon: <EventIcon />,
    link: "/adm/membership",
  },
  {
    title: "문의사항",
    icon: <EventIcon />,
    link: "/adm/qna",
  },
  {
    title: "앱 설정",
    icon: <SettingsIcon />,
    link: "/adm/setting",
  },
];
